import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`We’re still hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
text-to-speech (TTS) and speech-to-text (STT) engines (over 725K downloads and 28K GitHub
stars), with the backing of top-flight investors `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-tts-deep-learning-engineer"
        }}>{`Senior TTS Deep Learning Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "welcome-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#welcome-",
        "aria-label": "welcome  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Welcome! 👋🐸`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Ready for a new month packed to the brim with Coqui goodness?`}</p>
    <p>{`On November 10th Coqui will present at GTC! (Yes, that GTC, a conference that had over 50K
registrants last year.) We’ll be talking about the long tail of languages, how the majority
of the world speaks a “minority” language, and what Coqui’s doing about it. If you’d like
to hear more details,
`}<a parentName="p" {...{
        "href": "https://events.rainfocus.com/widget/nvidia/nvidiagtc/sessioncatalog?search=A31147&search=A31147"
      }}>{`register`}</a>{`!
(Also, at the end of our talk there’s a (secret) special surprise that you won’t want to
miss out on.)`}</p>
    <p>{`🐸💬TTS v0.4.0 is out the door! The rapid progress of Coqui’s TTS engine continues. This
newest version introduces:`}</p>
    <ul>
      <li parentName="ul">{`Multi-speaker support for all the 🐸TTS models,`}</li>
      <li parentName="ul">{`Updated Trainer API, and`}</li>
      <li parentName="ul">{`Two new pre-trained models.`}</li>
    </ul>
    <p>{`Along with much more TTS goodness.`}</p>
    <p>{`With 🐸 STT 1.0 hot off the presses, we aren’t resting on our laurels. This month we’ve
been working on an extension of our decoder package to greatly expand STT functionality,
eventually allowing for numerous types of scorers beyond what we currently support.
The `}<a parentName="p" {...{
        "href": "https://pypi.org/project/coqui-stt-ctcdecoder/1.1.0a1/"
      }}>{`latest 1.1.0 preview release`}</a>{` exposes
APIs that cover using acoustic models trained with CTC and ASG. So, if you’re looking to
explore, this is the place.`}</p>
    <p>{`Enjoy!`}</p>
    <h3 {...{
      "id": "coqui-gtc-and-the-long-tail-of-languages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-gtc-and-the-long-tail-of-languages",
        "aria-label": "coqui gtc and the long tail of languages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui, GTC, and the Long Tail of Languages`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACfUlEQVQ4y3VT60tTYRjfP9O3bjAkslXQF8uJmOmKXGXXpaOCID+oSMSIok9REBJGmRXDIV7SlpikM6MbNYPlbSor1thql7Ozc9k526/3fes9Ozp64Dnbec7z/J7fc7MUi0VQpcL/m981vQCdaKGw3k5FkWVkMhlIkkS+F5jNshGsQNUUzIWBEjUnlAlQMplEPp83MCzmjBtF03WksxJUVTVs1JOzkRUF6XSaJNNLgByMMognRSxHfiO4EAM1h1aiqL5wD46OXrTdHUYoOA+opeA8SSQIQjnDn3EBUx/X8CKwhLHpJQy++kZY6fi69B3bHddg3XcG1dt2o3OXFXdaGhH7tcoAVEVGlgDSCjgxiyip8AcWCdAixmfDmHi7gtGpecK4SNjGscNWDXvlVhyvr0SDYw/sDRXouupmtSuqglQqBVEUS4CJZJYxm3y/xgCpPh6YRjQahf/5COrsNajbX4XGxiOod55CrbMFB892kt4J0DWN9TCXy5WmLOZkPBt6A5//MybfreGhL4CpwAfMjI/A09UO17GjcJ0+CXfTIXQ0O9F62A5Xcy00QihPSk6nU1DIcAxA+liNxPDI+xLXbz/ByOhrhL58wmBfD3rvd6PtykV43OdwovUS6qsqYd20Bf1eLwuWZJkxlMlv2ZRVNY+F8A/EI2HcuHkLno7LaHefx94aOw5UbIZtpw0OZxMmxoaMFVLVv2tTNmW6yFwUKYvAzCxGhwfQ/7QP3T0PMOjzYi44xy4Cpj3UCFA2Kxh7uo4hu5J/jv8T7sNjNDIUOmHaQ8rSYGi+FnMQP8WNl2TcMgFKJBJsypzlOkAzKHWgAWalNt4vzpDaKAFe3R8IOOnRc8gHkQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/7049cfe0fa851646e1fba39a9398224d/da8b6/longtail_languages.png",
          "srcSet": ["/static/7049cfe0fa851646e1fba39a9398224d/43fa5/longtail_languages.png 250w", "/static/7049cfe0fa851646e1fba39a9398224d/c6e3d/longtail_languages.png 500w", "/static/7049cfe0fa851646e1fba39a9398224d/da8b6/longtail_languages.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`On November 10th Coqui will be afforded the honour of presenting at NVIDIA’s GTC conference
in the ”`}<a parentName="p" {...{
        "href": "https://events.rainfocus.com/widget/nvidia/nvidiagtc/sessioncatalog?search=A31147&search=A31147"
      }}>{`From Speech to Text: How Does AI Understand the Spoken Word`}</a>{`?”
session.`}</p>
    <p>{`GTC is NVIDIA’s yearly conference which focuses largely on artificial intelligence and deep
learning, and it’s huge. Last year the conference had over 50K registrants!`}</p>
    <p>{`Coqui’s presentation will focus on language’s long tail.`}</p>
    <p>{`Of the world’s roughly 7K languages there are a small number of languages that have over
50 million native speakers, e.g. Mandarin, Spanish, English. However, this isn’t the
entire story. There are a large number of languages with under 50 million native speakers.
In fact as of 2011 there were over `}<a parentName="p" {...{
        "href": "https://www.ethnologue.com/guides/ethnologue200"
      }}>{`3.1 billion native speakers`}</a>{`
of these “long tail languages”. As the world’s population was `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/World_population"
      }}>{`7 billion`}</a>{`
then, when one includes non-native speakers of these long tail languages you reach the
seemingly contradictory conclusion that the majority of the world speaks “minority”
languages?!`}</p>
    <p>{`This leads to a massive problem: Most of the languages in the long tail have no
speech technology, but the majority of the world speaks these long tail languages.`}</p>
    <p>{`Intrigued? If you’d like to hear more details on Coqui’s approach to the long tail,
I’d urge you to `}<a parentName="p" {...{
        "href": "https://events.rainfocus.com/widget/nvidia/nvidiagtc/sessioncatalog?search=A31147&search=A31147"
      }}>{`register`}</a>{`
to hear what Coqui has to say.`}</p>
    <h3 {...{
      "id": "-tts-v040-is-out-the-door",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-tts-v040-is-out-the-door",
        "aria-label": " tts v040 is out the door permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🐸💬 TTS v0.4.0 is Out the Door`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`This version introduces:`}</p>
    <ul>
      <li parentName="ul">{`Multi-speaker support for all the 🐸TTS models,`}</li>
      <li parentName="ul">{`Updated Trainer API, and`}</li>
      <li parentName="ul">{`Two new pre-trained models.`}</li>
    </ul>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.4.0"
      }}>{`release notes`}</a>{` for the details.`}</p>
    <p>{`All the `}<inlineCode parentName="p">{`TTS.tts`}</inlineCode>{` models now support multi-speaker training using speaker embedding layers.
You can find new recipes for training 🐸TTS models on the VCTK dataset and learn more about
multi-speaker training from our `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io/en/latest/training_a_model.html#multi-speaker-training"
      }}>{`documentation`}</a>{`.`}</p>
    <p>{`The new API simplifies the Trainer by making it responsible for only the model training and
removing the use-case specific details. Now the 🐸TTS Trainer can be used as a generic
training utility for deep learning models. We are actively working and improving the Trainer
API. More updates are on the way!`}</p>
    <p>{`We have also released two new `}<inlineCode parentName="p">{`TTS.tts`}</inlineCode>{` models:`}</p>
    <ul>
      <li parentName="ul">{`FastPitch model trained on the VCTK dataset.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`tts --model_name tts_models/en/vctk/fast_pitch --text "This is my sample text to voice." --speaker_idx VCTK_p229
`}</code></pre>
    <ul>
      <li parentName="ul">{`Ukrainian GlowTTS model from 👑 `}<a parentName="li" {...{
          "href": "https://github.com/robinhad/ukrainian-tts"
        }}>{`https://github.com/robinhad/ukrainian-tts`}</a></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`tts --model_name tts_models/uk/mai/glow-tts --text "Це зразок тексту, щоб спробувати нашу модель."
`}</code></pre>
    <h3 {...{
      "id": "extending-our-beam-search-decoder-package",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#extending-our-beam-search-decoder-package",
        "aria-label": "extending our beam search decoder package permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Extending our beam search decoder package`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMEBQf/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAcJWmBeQVAf/xAAbEAADAAIDAAAAAAAAAAAAAAABAgMABBESFP/aAAgBAQABBQKKv57Is5oEskiONmvaIYjP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AYx//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EABwQAQADAAIDAAAAAAAAAAAAAAEAAhEQMiJBYf/aAAgBAQAGPwLotfqRsVKoQshs6V8faazMDj//xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAxIUFRYXHR/9oACAEBAAE/IXRQNRdeAdYh1CbB9lc74LmNU5yRtz5+ZbiU0GGwc//aAAwDAQACAAMAAAAQc8//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAh/9oACAEDAQE/EIYZd47/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxBj/8QAGxABAAIDAQEAAAAAAAAAAAAAAREhADFBUWH/2gAIAQEAAT8QdXblyDJMdhYefcrZlCwdSbYkQaxx5iGsIvOVGCSeoxMqI5GI0YijR6yA35r5lFQ3rP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/32a4a2c6799dfaa882330f9e46c0cb78/dbdff/beam_search_decoder.jpg",
          "srcSet": ["/static/32a4a2c6799dfaa882330f9e46c0cb78/0988f/beam_search_decoder.jpg 250w", "/static/32a4a2c6799dfaa882330f9e46c0cb78/d1f95/beam_search_decoder.jpg 500w", "/static/32a4a2c6799dfaa882330f9e46c0cb78/dbdff/beam_search_decoder.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`Coqui STT uses two main subsystems for transcribing speech: an acoustic model which processes the
audio and produces a sequence of character probabilities over time, and a beam search decoder
which transforms these probabilities into actual transcripts, possibly with the help of an
external scorer trained on text data.`}</p>
    <p>{`Enabling usage of scorers built entirely from text data lets users customize the transcription
process in a faster and cheaper way than collecting audio for specialized vocabularies or
domains. The beam search decoder also enables features such as `}<a parentName="p" {...{
        "href": "https://stt.readthedocs.io/en/latest/HotWordBoosting-Examples.html"
      }}>{`hot-word boosting`}</a>{`,
extracting timing metadata with the outputs, constraining the output to a fixed lexicon,
as well as offering several optimization knobs to trade accuracy for speed.`}</p>
    <p>{`As we start to experiment with new `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Transformer_(machine_learning_model)"
      }}>{`transformer-based architectures`}</a>{`
for speech recognition, it’s important to have flexibility in the beam search decoder in
order to quickly experiment with different combinations of acoustic models and textual
scorers. One can, for example, use a scorer trained on word n-grams, or character n-grams,
trading accuracy for model size and transcription speed. For acoustic model architectures
that blend the line between audio and text modelling, themselves embedding long term
textual knowledge, the decoder features allow for re-using a single high quality acoustic
model for many different tasks by constraining its recognition space to certain jargon or
a specific list of phrases and words.`}</p>
    <p>{`In order to efficiently experiment with these new architectures, we’re integrating the
`}<a parentName="p" {...{
        "href": "https://github.com/flashlight/flashlight/"
      }}>{`flashlight library`}</a>{` in our decoder
package. This means new APIs for decoding with word-based external scorers, character-based
external scorers, and acoustic models trained with CTC, ASG, or Seq2seq loss criteria.`}</p>
    <p>{`On the `}<a parentName="p" {...{
        "href": "https://pypi.org/project/coqui-stt-ctcdecoder/1.1.0a1/"
      }}>{`latest 1.1.0 preview release`}</a>{`, the
exposed APIs cover using acoustic models trained with CTC and ASG. We’re excited about
the possibilities here, and will soon be adding support for these models in the STT
deployment packages for use with our `}<a parentName="p" {...{
        "href": "https://coqui.ai/models/"
      }}>{`pre-trained models`}</a>{`.
Join `}<a parentName="p" {...{
        "href": "https://gitter.im/coqui-ai/STT"
      }}>{`our 🐸STT chat room on Gitter`}</a>{` and get involved!`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      